import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

import Text from '@components/text';
import Link from '@components/link';
import BlockSanity from '@components/block-sanity';

const Wrapper = styled.div`
  bottom: -210px;
  min-width: 280px;
  ${tw`absolute left-1/2 flex transform -translate-x-1/2 md:transform-none`}
  
  @media (min-width: 768px) {
    bottom: -320px;
    left: 400px;
    min-width: 320px;
  }
  
  @media (min-width: 1024px) {
    bottom: -400px;
    left: 650px;
  }

  @media (min-width: 1280px) {
    bottom: -440px;
    left: 810px;
  }
`;

const Dot = styled(Img)`
  top: 30px;
  min-width: 24px;
  ${tw`hidden md:block md:w-24 md:h-24 md:mr-25`}
`;

const Box = tw(Link)`py-28 px-20 bg-white rounded-xl shadow-coffeeHouse`;

const Place = ({ address, link }) => {
  const data = useStaticQuery(graphql`
    query {
      listStyle: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Dot fluid={data.listStyle.childImageSharp.fluid} />
      <Box to={link} isExternal>
        <Text fontSizes={16} lineHeight={1.4} letterSpacing={20}>
          <BlockSanity content={address} noSpacing />
        </Text>
      </Box>
    </Wrapper>
  );
};

Place.propTypes = {
  address: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
  link: PropTypes.string.isRequired,
};

export default Place;
