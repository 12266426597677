import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ListItem from '@components/coffee-house/list-item';

const ListWrapper = tw.div`mr-35 md:mr-75`;

const ListTitle = tw(Text)`mb-18`;

const List = ({ name, list, locale }) => (
  <ListWrapper>
    <ListTitle fontSizes={16} fontWeight={600} letterSpacing={20}>
      <FormattedMessage id={`coffee houses.lists.${name}`} />
    </ListTitle>
    {list.map((item) => (
      <ListItem key={item.name_en} item={item[`name_${locale}`]} />
    ))}
  </ListWrapper>
);

List.propTypes = {
  name: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.string,
    ),
  ).isRequired,
  locale: PropTypes.string.isRequired,
};

export default List;
