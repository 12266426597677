import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-intl';
import { customContainer } from '@utils/twin-macro';

import Layout from '@layouts/';
import SEO from '@components/seo.jsx';
import Images from '@components/coffee-house/images';
import Content from '@components/coffee-house/content';

const Wrapper = tw.div`w-full max-w-1440 mx-auto overflow-hidden bg-beige`;

const Container = styled.div`
  ${customContainer}
  ${tw`relative pt-70 pb-300 px-10 md:pb-375 lg:pt-100 lg:pb-450 lg:px-0 xl:pb-500`}
`;

const CoffeeHouse = ({ data }) => {
  const intl = useIntl();

  return (
    <Layout
      switchPath={[
        `/kawiarnie/${data.sanityCoffeeHouse.slug.current}`,
        `/en/coffee-shops/${data.sanityCoffeeHouse.slug.current}`,
      ]}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage(
          { id: 'meta tags.title.coffee house' },
          { place: data.sanityCoffeeHouse[`name_${intl.locale}`] },
        )}
        description={data.sanityCoffeeHouse[`meta_${intl.locale}`] || ''}
        img={data.image.childImageSharp.fixed.src}
      />
      <Wrapper>
        <Container>
          <Images
            main={data.sanityCoffeeHouse.image.asset.fluid}
            map={data.sanityCoffeeHouse.map.asset.fluid}
          />
          <Content place={data.sanityCoffeeHouse} locale={intl.locale} />
        </Container>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    sanityCoffeeHouse(id: { eq: $id }) {
      _rawAddress
      _rawDescriptionEn
      _rawDescriptionPl
      name_pl
      name_en
      meta_en
      meta_pl
      slug {
        current
      }
      hours_en
      hours_pl
      amenities {
        name_en
        name_pl
      }
      operate {
        name_en
        name_pl
      }
      map {
        asset {
          fluid(maxWidth: 2000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      image {
        asset {
          fluid(maxWidth: 850) {
            ...GatsbySanityImageFluid
          }
        }
      }
      map_link_en
      map_link_pl
      menu_en {
        asset {
          url
        }
      }
      menu_pl {
        asset {
          url
        }
      }
    }
    image: file(relativePath: { eq: "meta-img-shops.png" }) {
      childImageSharp {
        fixed(width: 1200, quality: 100) {
          src
        }
      }
    }
  }
`;

CoffeeHouse.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any.isRequired,
};

export default CoffeeHouse;
