import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { graphql, useStaticQuery } from 'gatsby';

import PathDefault from '@images/coffee-house.svg';

const Path = styled(PathDefault)`
  ${tw`hidden md:block`}
  position: absolute;
  width: 398px;
  left: 520px;
  top: -160px;
  
  @media (min-width: 1024px) {
    left: 780px;
    top: -100px;
  }

  @media (min-width: 1280px) {
    left: 885px;
  }
`;

const DefaultImg = tw(Img)`absolute!`;

const Map = styled(DefaultImg)`
  bottom: -15px;
  width: 1000px;
  left: 50%;
  transform: translateX(-50%);
  
  @media (min-width: 768px) {
    bottom: -20px;
    width: 1220px;
    left: -422px;
    transform: none;
  }
  
  @media (min-width: 1024px) {
    bottom: -35px;
    width: 1300px;
    left: -230px;
  }

  @media (min-width: 1280px) {
    left: -170px;
    width: 1440px;
  }
`;

const Main = styled(DefaultImg)`
  ${tw`hidden md:block`}
  bottom: 190px;
  left: 360px;
  width: 540px;
  
  @media (min-width: 1024px) {
    bottom: 202px;
    left: 430px;
    width: 690px;
  }

  @media (min-width: 1280px) {
    bottom: 238px;
    left: 590px;
  }
`;

const CoffeeBeans = styled(DefaultImg)`
  ${tw`hidden md:block`}
  top: -35px;
  left: 600px;
  width: 172px;
  
  @media (min-width: 1024px) {
    left: 890px;
  }

  @media (min-width: 1280px) {
    left: 1005px;
  }
`;

const CoffeeBeansSmall = styled(DefaultImg)`
  top: 26px;
  left: -44px;
  width: 82px;
  
  @media (min-width: 1024px) {
    top: 47px;
  }
  
  @media (min-width: 1280px) {
    left: -82px;
  }
`;

const CoffeeMark = styled(DefaultImg)`
  ${tw`hidden xl:block`}
  
  @media (min-width: 1280px) {
    top: 630px;
    left: -225px;
    width: 175px;
  }
`;

const Images = ({ main, map }) => {
  const data = useStaticQuery(graphql`
    query {
      coffeeBeans: file(relativePath: { eq: "coffee-beans.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coffeeBeansSmall: file(relativePath: { eq: "coffee-beans-small.png" }) {
        childImageSharp {
          fluid(maxWidth: 140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coffeeMark: file(relativePath: { eq: "coffee-mark.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Path />
      <Map fluid={map} />
      <Main fluid={main} />
      <CoffeeBeans fluid={data.coffeeBeans.childImageSharp.fluid} />
      <CoffeeBeansSmall fluid={data.coffeeBeansSmall.childImageSharp.fluid} />
      <CoffeeMark fluid={data.coffeeMark.childImageSharp.fluid} />
    </>
  );
};

Images.propTypes = {
  main: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
  map: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
};

export default Images;
