import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import ButtonDefault from '@components/button';
import LinkDefault from '@components/link';

const Link = tw(LinkDefault)`inline-block`;

const Button = tw(ButtonDefault)`mt-30 px-50 lg:mt-35`;

const CTA = ({ link }) => (
  <Link to={link} isExternal>
    <Button>
      <Text color="white" fontSizes={16} fontWeight={600} lineHeight={1.4} letterSpacing={20}>
        <FormattedMessage id="btn.menu" />
      </Text>
    </Button>
  </Link>
);

CTA.propTypes = {
  link: PropTypes.string.isRequired,
};

export default CTA;
