import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '@utils/helpers';

import Header from '@components/coffee-house/header';
import List from '@components/coffee-house/list';
import OpeningHours from '@components/coffee-house/opening-hours';
import CTA from '@components/coffee-house/cta';
import Place from '@components/coffee-house/place';

const ListWrapper = tw.div`flex max-w-xs md:max-w-sm xl:max-w-md`;

const lists = ['operate', 'amenities'];

const Wrapper = tw.div`relative`;

const Content = ({ place, locale }) => (
  <Wrapper>
    <Header
      title={place[`name_${locale}`]}
      desc={place[`_rawDescription${capitalizeFirstLetter(locale)}`]}
    />
    <ListWrapper>
      {lists.map((name) => (
        <List key={name} name={name} list={place[name]} locale={locale} />
      ))}
    </ListWrapper>
    {/* eslint-disable-next-line no-underscore-dangle */}
    <OpeningHours hours={place[`hours_${locale}`]} address={place._rawAddress} />
    <CTA link={place[`menu_${locale}`].asset.url} />
    {/* eslint-disable-next-line no-underscore-dangle */}
    <Place address={place._rawAddress} link={place[`map_link_${locale}`]} />
  </Wrapper>
);

Content.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  place: PropTypes.any.isRequired,
  locale: PropTypes.string.isRequired,
};

export default Content;
