import React from 'react';
import tw, { styled } from 'twin.macro';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import Text from '@components/text';

const Wrapper = tw.div`flex mb-35`;

const ListImg = styled(Img)`
  min-width: 18px;
  max-height: 18px;
  ${tw`w-18 mr-15`}
`;

const ListItem = ({ item }) => {
  const data = useStaticQuery(graphql`
    query {
      listStyle: file(relativePath: { eq: "cafe-second.png" }) {
        childImageSharp {
          fluid(maxWidth: 40) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <ListImg fluid={data.listStyle.childImageSharp.fluid} />
      <Text fontSizes={16} fontWeight={300} letterSpacing={20}>
        {item}
      </Text>
    </Wrapper>

  );
};

ListItem.propTypes = {
  item: PropTypes.string.isRequired,
};

export default ListItem;
