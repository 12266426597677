import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import BlockSanity from '@components/block-sanity';

const Hours = styled(Text)`
  b { ${tw`font-600`} }
`;

const Address = tw(Text)`mt-22`;

const OpeningHours = ({ hours, address }) => (
  <>
    <Hours fontSizes={16} fontWeight={300} lineHeight={1.4} letterSpacing={20}>
      <b><FormattedMessage id="coffee houses.houses.opening hours" /></b>
      {hours.map((hour, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          {hour}
        </div>
      ))}
    </Hours>
    <Address fontSizes={16} fontWeight={300} lineHeight={1.4} letterSpacing={20}>
      <BlockSanity content={address} noSpacing />
    </Address>
  </>
);

OpeningHours.propTypes = {
  hours: PropTypes.arrayOf(PropTypes.string).isRequired,
  address: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default OpeningHours;
