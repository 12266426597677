import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import Text from '@components/text';
import BlockSanity from '@components/block-sanity';

const Name = tw(Text)`mb-15`;

const Desc = styled(Text)`
  ${tw`mb-20 max-w-xs md:max-w-none`}

  & > div * { ${tw`inline md:block`} }
`;

const Header = ({ title, desc }) => (
  <>
    <Name fontFamily="bebasNeue" fontSizes={[40, 54]} fontWeight={700} letterSpacing={68}>
      {title}
    </Name>
    <Desc fontSizes={16} fontWeight={300} lineHeight={1.5} letterSpacing={20}>
      <BlockSanity content={desc} noSpacing />
    </Desc>
  </>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.arrayOf(
    PropTypes.any,
  ).isRequired,
};

export default Header;
